<template>
  <div>
    <portal-target name="sidebar-notes" slim />
    <b-card
      class="fixed-top-component border-warning"
      style="border-width: 2px !important"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div class="title-process">
            Credit Report
            <span class="title-platform">({{ nameProvider }})</span>
          </div>
          <b-badge :variant="`light-${process == 1 ? 'success' : 'warning'}`">
            {{ processInfo }}
          </b-badge>
        </div>
        <div
          class="d-flex justify-content-between align-items-center"
          style="gap: 10px"
        >
          <b-button variant="info" @click="showAccessCr" v-if="showInfoClient">
            <feather-icon icon="KeyIcon" />
            {{ showFiles && gridName == "showAccessCr" ? "Hide" : "Show" }}
            Access Credentials
          </b-button>
          <b-button variant="info" class="p-0" v-if="updateFiles.length > 0">
            <b-dropdown variant="link" no-caret boundary="viewport" class="p-0">
              <template #button-content>
                <span class="text-white">
                  <feather-icon icon="FileIcon" />
                  Update Files
                  <span
                    v-if="updateFiles.length > 0"
                    class="bg-danger text-white rounded-circle"
                    style="padding: 1px 5px"
                    >{{ updateFiles.length }}
                  </span>
                </span>
              </template>
              <b-dropdown-item
                v-for="file in updateFiles"
                :key="file.id"
                :href="file.url"
                target="_blank"
              >
                <amg-icon
                  v-b-tooltip.hover.top="'View PDF'"
                  class="cursor-pointer mt-0 mr-0 bigger text-danger"
                  icon="FilePdfIcon"
                  style="height: 18px; width: 18px"
                />
                <span>{{
                  file.file_name.length > 15
                    ? file.file_name.slice(0, 15).concat("...")
                    : file.file_name
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-button>
          <b-button
            variant="info"
            @click="showFilesCollapsed"
            v-if="showInfoClient"
          >
            <feather-icon icon="FileIcon" />
            {{ showFiles && gridName == "showFiles" ? "Hide" : "Show" }} Files
          </b-button>
          <b-button
            variant="primary"
            :class="showInfoClient ? null : 'collapsed'"
            @click="toggleInfoClient"
            aria-controls="collapse-user"
            :aria-expanded="showInfoClient"
          >
            <feather-icon icon="UserIcon" />
            {{ showInfoClient ? "Hide" : "Show" }} Client Info
          </b-button>
          <!-- <b-button variant="info" @click="openModalApps">
            <feather-icon icon="EyeIcon" class="mr-half" />APPLICATIONS
          </b-button> -->
          <b-button variant="outline-warning" @click="openFirstNoteModal">
            <feather-icon icon="FileTextIcon" class="mr-half" />FIRST NOTE
          </b-button>
          <b-button variant="warning" @click="returnPage()">
            <feather-icon icon="ChevronsLeftIcon" /> Return
          </b-button>
        </div>
      </div>
      <b-collapse v-model="showInfoClient" id="collapse-user">
        <b-card class="border-1 border-secondary mt-2">
          <PersonalInformation
            :clientId="clientId"
            v-if="clientId"
            :showFiles="showFiles"
            :gridName="gridName"
          />
          <div v-else>No client selected</div>
        </b-card>
      </b-collapse>
    </b-card>

    <ValidationObserver ref="accounts" tag="div">
      <PersonalProfile
        :score="datos.score"
        :day_processed_format="day_processed_format"
        :datos="datos"
        :personal_info="personal_info"
        :process="process"
        :day_processed="day_processed"
        :round_id="round_id"
        :idSection="idSection"
        :section="'update'"
      />

      <CreditSummary :datos="datos" :val="plataform_id" />

      <CreditInquiries
        :process="process"
        :inquires="inquires"
        :day_processed="day_processed"
        :idSection="idSection"
        :round_id="round_id"
        :section="'update'"
      />

      <PublicInformation
        :public_info="public_info"
        :day_processed="day_processed"
        :round_id="round_id"
        :process="process"
        :idSection="idSection"
        @setPublicRecord="setPublicRecord = $event"
        :section="'update'"
      />

      <Accounts
        :accounts="accounts"
        :process="process"
        :day_processed="day_processed"
        :round_id="round_id"
        :idSection="idSection"
        @setAccounts="setAccounts = $event"
        :section="'update'"
      />
    </ValidationObserver>
    <b-row>
      <b-col cols="4" class="text-center offset-4" v-if="isForSpecialist">
        <b-button
          variant="success"
          class="rounded mt-2 mb-1"
          :disabled="disable.save"
          @click="processReport()"
          >PROCESS</b-button
        >
      </b-col>
      <b-col cols="6" class="text-center offset-3" v-if="isForRound">
        <b-button
          variant="success"
          class="rounded mt-2 ml-1 mb-1"
          @click="toRevision(requestWpdataClient)"
          :disabled="validationButtonCompleted"
          :class="
            this.G_DISPUTE_DATA.length == 0
              ? 'cursor-not-allowed'
              : 'cursor-pointer'
          "
          >COMPLETE DISPUTE
        </b-button>
      </b-col>
    </b-row>

    <ModalRecommendService
      v-if="showModalRecommend"
      @close="showModalRecommend = false"
      :dataClient="requestWpdataClient"
      :idSection="idSection"
      :comment="comment"
    />
    <ModalRound
      v-if="showModalRound"
      :roundLetterId="round_id"
      :dataClient="requestWpdataClient"
      @close="showModalRound = false"
      :idBureau="idBureau"
      :idSection="idSection"
    />
    <modal-view-applications
      v-if="openModalAppsController"
      @close="closeModalApps"
    />
    <!--First Note Modal-->
    <ModalNotesAll
      v-if="firstNoteController && programId == null"
      :note-info="noteInfo"
      :is-from-task-notes="true"
      @hide="closeFirstNoteModal"
    />
    <ModalNotesTax
      v-if="firstNoteController && programId == 5"
      :note-info="noteInfo"
      @hide="closeFirstNoteModal"
    />
    <ModalNotesBoost
      v-if="firstNoteController && programId == 2"
      :note-info="noteInfo"
      @hide="closeFirstNoteModal"
    />
    <ModalNotesFirst
      v-if="firstNoteController && programId == 1"
      :note-info="noteInfo"
      @hide="closeFirstNoteModal"
    />
    <ModalNotesSpecialist
      v-if="firstNoteController && programId == 7"
      :note-info="noteInfo"
      @hide="closeFirstNoteModal"
    />
    <ModalNotasCredit
      v-if="firstNoteController && programId == 3"
      :note-info="noteInfo"
      @hide="closeFirstNoteModal"
    />
  </div>
</template>

<script>
import { amgApi } from "@/service/axios";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import vSelect from "vue-select";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import CreditSummary from "@/views/commons/components/ncr/components/report/components/CreditSummary.vue";
import CreditInquiries from "@/views/commons/components/ncr/components/report/components/CreditInquiries.vue";
import PublicInformation from "@/views/commons/components/ncr/components/report/components/PublicInformation.vue";
import Accounts from "@/views/commons/components/ncr/components/report/components/Accounts.vue";
import ModalRecommendService from "@/views/commons/components/ncr/components/report/modal/ModalRecommendService.vue";
import ReportLeadService from "@/views/commons/components/ncr/components/report/service/report.service";
import ModalViewApplications from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalViewApplications.vue";
/**V2 */
import PersonalInformation from "@/views/commons/components/ncr/components/report/components/client-info/PersonalInformation.vue";
import PersonalProfile from "@/views/commons/components/ncr/components/report/components/PersonalProfile.vue";
import ModalRound from "@/views/commons/components/ncr/components/report/modal/ModalRound.vue";
import ReportService from "@/views/commons/components/ncr/service/ncr.service";
import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";
/**First Note */
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import ModalNotesBoost from "@/views/commons/components/first-notes/ModalNotesBoost.vue";
import ModalNotesTax from "@/views/commons/components/first-notes/ModalNotesTax.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
import ModalNotesParagon from "@/views/commons/components/first-notes/ModalNotesParagon.vue";
import ModalNotesSpecialist from "@/views/commons/components/first-notes/ModalNotesSpecialist.vue";
import ModalNotasCredit from "@/views/commons/components/first-notes/ModalNotasCredit.vue";

export default {
  components: {
    PersonalProfile,
    PersonalInformation,
    ModalRound,
    FeatherIcon,
    vSelect,
    CreditSummary,
    CreditInquiries,
    PublicInformation,
    Accounts,
    ModalRecommendService,
    ModalViewApplications,
    ModalNotesAll,
    ModalNotesBoost,
    ModalNotesTax,
    ModalNotesFirst,
    ModalNotesParagon,
    ModalNotesSpecialist,
    ModalNotasCredit,
  },
  props: {
    idfile: [Number, String],
    idlead: [Number, String],
    rwId: [Number, String],
    accountId: [Number, String],
    idSection: [Number, String, Boolean],
    section: [Number, String, Boolean],
  },
  data() {
    return {
      openModalAppsController: false,
      updateFiles: [],
      validClassifications: true,
      showInfoClient: false,
      plataform_id: null,
      clientId: null,
      datos: {},
      status: [],
      viewreport: false,
      rounds: [],
      process: "",
      data: [],
      personal_info: [],
      inquires: [],
      public_info: [],
      accounts: [],
      modalRound: false,
      showModalRecommend: false,
      showModalRound: false,
      requestWpdataClient: null,
      disable: {
        save: false,
        color: "#f1f1f1",
      },
      round_id: "",
      secure_number: {
        ssn: null,
        itin: null,
        other: null,
      },
      round: {
        title: null,
        format: null,
        type: null,
        secure_number: null,
      },
      headerPublics: [
        {
          key: "content",
          label: "Type",
        },
        {
          key: "status_show",
          label: "Status",
        },
        {
          key: "date",
          label: "Date",
        },
        {
          key: "reference",
          label: "Reference",
        },
        {
          key: "closing",
          label: "Closing",
        },
        {
          key: "court",
          label: "Court",
        },
      ],
      headerAccounts: [
        {
          key: "content",
          label: "Account Name",
        },
        {
          key: "a_number",
          label: "Account #",
        },
        {
          key: "type",
          label: "Account Type",
        },
        {
          key: "a_status",
          label: "Account Status",
        },
        {
          key: "a_description",
          label: "Account Description",
        },
        {
          key: "monthly",
          label: "Monthly Payment",
        },
        {
          key: "date",
          label: "Date Opened",
        },
        {
          key: "balance",
          label: "Balance",
        },
        {
          key: "n_months",
          label: "No. of Months (terms)",
        },
        {
          key: "h_credit",
          label: "Highest Credit",
        },
        {
          key: "c_limit",
          label: "Credit Limit",
        },
        {
          key: "p_status",
          label: "Payment Status",
        },
        {
          key: "comments",
          label: "Comments",
        },
        {
          key: "lp_status",
          label: "Last Payment Status",
        },
        {
          key: "detail",
          label: "Show More Details",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "status_dispute",
          label: "Status Dispute",
        },
      ],
      setInq: [
        {
          type_of_disput: null,
          reason: null,
          instruction: null,
          select: false,
        },
        {
          type_of_disput: null,
          reason: null,
          instruction: null,
          select: false,
        },
        {
          type_of_disput: null,
          reason: null,
          instruction: null,
          select: false,
        },
      ],
      setPersonal: [
        {
          reason: null,
          instruction: null,
        },
        {
          reason: null,
          instruction: null,
        },
        {
          reason: null,
          instruction: null,
        },
      ],
      setDisputeInquire: [
        {
          type_of_disput: null,
        },
        {
          type_of_disput: null,
        },
        {
          type_of_disput: null,
        },
      ],
      setInquires: {
        reason: null,
        instruction: null,
      },
      setAccounts: {
        reason: [],
        instruction: [],
      },
      setPublicRecord: {
        reason: [],
        instruction: [],
      },
      editScore: false,
      editInquire: false,
      oldAccounts: [],
      oldInfo: [],
      processed_by: "",
      processed_at: "",
      request_wp_id: null,
      addRoundBtn: true,
      btnRound: false,
      method: null,
      titles: [],
      formats: [],
      roundPaginator: 0,
      day_processed: null,
      day_processed_format: null,
      modalInfo: false,
      type_selected: null,
      comment: null,
      clear_datos: {
        date: moment().format("MM/DD/YYYY"),
        score: [{ score: "0" }, { score: "0" }, { score: "0" }],
        personal: [],
        sumary: [
          {
            t_account: 0,
            o_account: 0,
            c_account: 0,
            balance: 0,
            payments: 0,
          },
          {
            t_account: 0,
            o_account: 0,
            c_account: 0,
            balance: 0,
            payments: 0,
          },
          {
            t_account: 0,
            o_account: 0,
            c_account: 0,
            balance: 0,
            payments: 0,
          },
        ],
        inquires: [],
        account: [],
        public: [],
      },
      data_fake: [
        {
          account: [],
          inquires: [],
          personal: [],
          public: [],
        },
      ],
      showFiles: false,
      gridName: null,
      noteInfo: null,
      firstNoteController: false,
      programId: null,
    };
  },

  async beforeDestroy() {
    await this.A_CLEAR_DISPUTE_DATA([]);
    await this.A_ID_REQUEST_WORKPLAN(null);
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_DISPUTE_DATA: "RequestWorkPlanStore/G_DISPUTE_DATA",
      G_ID_REQUEST_WORKPLAN: "RequestWorkPlanStore/G_ID_REQUEST_WORKPLAN",
      client: "DebtSolutionClients/G_CLIENTS",
    }),

    currentRoute() {
      return this.$route.name;
    },
    validationButtonCompleted() {
      if (
        (this.$route.params.validationCompleted &&
          this.G_DISPUTE_DATA.length != 0) ||
        !this.$route.params.validationCompleted
      ) {
        return false;
      } else {
        return true;
      }
    },

    processInfo() {
      return this.process != 1
        ? "UNPROCESSED"
        : "PROCESSED BY: " + this.processed_by + " - " + this.processed_at;
    },
    nameProvider() {
      const names = {
        1: "IdentityIQ",
        3: "PrivacyGuard",
        7: "MyScoreIQ",
        8: "MyFreeScoreNow",
      };
      return names[this.plataform_id];
    },
    isForSpecialist() {
      return this.process != 1 && this.moduleId == 28;
    },
    isForRound() {
      return (
        this.process == 1 &&
        this.moduleId == 28 &&
        this.day_processed > "2021-09-27"
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    idBureau() {
      return this.$route.params.idBureau;
    },
  },
  async created() {
    await this.generateReport();
    // this.getSelectsOptions()
    this.alltitles();
    this.allFormats();
    await this.getClientWpReport();
    await this.getUpdateFiles();
    await this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
      id: this.$route.params.accountId,
    });
  },
  methods: {
    ...mapActions({
      A_CLEAR_DISPUTE_DATA: "RequestWorkPlanStore/A_CLEAR_DISPUTE_DATA",
      A_ID_REQUEST_WORKPLAN: "RequestWorkPlanStore/A_ID_REQUEST_WORKPLAN",
    }),
    toggleInfoClient() {
      this.showInfoClient = !this.showInfoClient;
      if (this.showInfoClient) {
        this.clientId = this.$route.params.accountId;
      }
      this.gridName = "showInfoMain";
      this.showFiles = false;
    },

    async getDataOfScore() {
      try {
        const res = await ReportLeadService.getCreditReportData({
          score_id: this.idfile,
          account_id: this.$route.params.accountId,
        });
        this.reloadHeaders();
        //Add Scores
        this.datos.score[0].score = res.data.scores[0].transunion;
        this.datos.score[1].score = res.data.scores[0].experian;
        this.datos.score[2].score = res.data.scores[0].equifax;
        //Charge Data Accounts
        for (const element of res.data.accounts) {
          this.addAccount();
        }
        res.data.accounts.map((item, i) => {
          item.map((element, j) => {
            let key = element.bureau_id - 1;
            this.accounts[i].element[key].a_description = element.a_description;
            this.accounts[i].element[key].a_number = element.a_number;
            this.accounts[i].element[key].a_status = element.a_status;
            this.accounts[i].element[key].balance = element.balance;
            this.accounts[i].element[key].bureau_id = element.bureau_id;
            this.accounts[i].element[key].c_limit = element.c_limit;
            this.accounts[i].element[key].h_credit = element.h_credit;
            this.accounts[i].element[key].comments = element.comments;
            this.accounts[i].element[key].content = element.content;
            this.accounts[i].element[key].date = element.date;
            this.accounts[i].element[key].id = element.id;
            this.accounts[i].element[key].lp_status = element.lp_status;
            this.accounts[i].element[key].monthly = element.monthly;
            this.accounts[i].element[key].n_months = element.n_months;
            this.accounts[i].element[key].p_status = element.p_status;
            this.accounts[i].element[key].type = element.type;
            this.accounts[i].element[key].status = element.status;
            this.accounts[i].element[key].classification =
              element.classification;
            this.accounts[i].element[key].type_send = element.type_send;
            this.accounts[i].element[key].title_id = element.title_id;
            this.accounts[i].element[key].status_dispute =
              element.status_dispute;
            this.accounts[i].element[key].new_account = element.new_account;
          });
        });
        //End Charge Data Accounts

        //Charge Data Inquires
        res.data.inquires.map((item, i) => {
          item.map((element, j) => {
            this.inquires.push({
              content: element.content,
              id: element.id,
              bureau_id: element.bureau_id,
              date_show: element.date_show,
              sub_content: element.sub_content,
              status: element.status,
              sub_id: element.sub_id,
              to_select: false,
              type_of_disput: null,
              reason: null,
              instruction: null,
              for_edit: false,
            });
          });
        });
        //End Charge Data Inquires

        //Charge Data Personal Information
        res.data.personal.map((item, i) => {
          item.map((element, j) => {
            this.personal_info.push({
              content: element.content,
              id: element.id,
              bureau_id: element.bureau_id,
              type: element.type,
              status: element.status,
              check: element.status == 6,
              title_id: element.title_id,
              type_send: element.type_send,
              to_select: false,
              type_of_disput: null,
              reason: null,
              instruction: null,
              for_edit: false,
            });
          });
        });
        //End Charge Data Personal Information

        //Charge Data Public Record
        for (const element of res.data.public) {
          this.addPublicRecord();
        }
        res.data.public.map((item, i) => {
          item.map((element, j) => {
            let key = element.bureau_id - 1;
            this.public_info[i].element[key].content = element.content;
            this.public_info[i].element[key].status = element.status;
            this.public_info[i].element[key].bureau_id = element.bureau_id;
            this.public_info[i].element[key].court = element.court;
            this.public_info[i].element[key].date = element.date;
            this.public_info[i].element[key].id = element.id;
            this.public_info[i].element[key].reference = element.reference;
            this.public_info[i].element[key].closing = element.closing;
            this.public_info[i].element[key].status_show = element.status_show;
            this.public_info[i].date = element.date;
            this.public_info[i].to_select = false;
            this.public_info[i].element[key].type_send = element.type_send;
            this.public_info[i].element[key].title_id = element.title_id;
          });
        });
        this.round_id = res.data.round_id;
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },

    async generateReport() {
      try {
        this.addPreloader();
        const response = await ReportLeadService.getReports({
          id: this.idfile,
        });

        if (response.data[0].cr == null) {
          this.clear_datos.score[0].score = response.data[0].transunion;
          this.clear_datos.score[1].score = response.data[0].experian;
          this.clear_datos.score[2].score = response.data[0].equifax;
        }

        if (response.status == 200) {
          this.datos =
            response.data.length > 0
              ? response.data[0].type_ncr == "AUTOMATIC"
                ? response.data[0].cr
                : this.clear_datos
              : this.clear_datos;
          this.data =
            response.data.length > 0
              ? response.data[0].cra || this.data_fake
              : this.data_fake;

          this.plataform_id = response.data[0].plataform_id;
          this.process = response.data[0].process ?? 0;
          this.processed_by = response.data[0].processed_by;
          this.processed_at = response.data[0].processed_at;
          this.day_processed = response.data[0].day_processed;
          this.day_processed_format = this.day_processed_format
            ? moment(this.day_processed).format("MM/DD/YYYY")
            : moment().format("MM/DD/YYYY");
          this.viewreport = true;
          this.request_wp_id = response.data[0].id;

          this.A_ID_REQUEST_WORKPLAN(response.data[0].id);

          if (this.isForRound) {
            await this.getDataOfScore();
          } else {
            if(this.data[0]?.account !== "") this.buildArrayAccounts();
            if(this.data[0]?.inquires !== "") this.buildArrayInquires();
            if(this.data[0]?.personal !== "") this.buildArrayPersonal();
            if(this.data[0]?.public !== "") this.buildArrayPublic();
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },

    // build array personal info
    buildArrayInquires() {
      let inquires = this.data[0]?.inquires ?? [];
      let x = 1;
      inquires.map((item) => {
        switch (this.plataform_id == 2 ? item[2] : item[3]) {
          case "TransUnion":
            x = 1;
            break;
          case "Experian":
            x = 2;
            break;
          case "Equifax":
            x = 3;
            break;
        }
        this.inquires.push({
          content: item[0],
          sub_content: item[1],
          status: 6,
          bureau_id: x,
          date: moment(this.plataform_id == 2 ? item[1] : item[2]).format(
            "YYYY--MM-DD"
          ),
          date_show: moment(this.plataform_id == 2 ? item[1] : item[2]).format(
            "MM/DD/YYYY"
          ),
          for_edit: false,
        });
      });
    },

    // build array public info
    buildArrayPublic() {
      let publicr = this.data[0]?.public ?? [];
      publicr.map((item) => {
        let date =
          item[0].date != ""
            ? item[0].date
            : item[1].date != ""
            ? item[1].date
            : item[2].date;
        let elements = [];
        item.map((value, index) => {
          elements.push({
            content: value.type == "" ? 0 : value.type,
            status: 6,
            bureau_id: index + 1,
            court: value.court,
            date: value.date,
            reference: value.reference,
            closing: value.closing,
            status_show: value.status,
            round: null,
            edit: false,
          });
        });

        this.public_info.push({
          date: moment(date).format("YYYY--MM-DD"),
          element: elements,
        });
        this.oldInfo = JSON.parse(JSON.stringify(this.public_info));
      });
    },

    // build array accounts
    buildArrayPersonal() {
      console.log(this.data)
      let personal = this.data[0]?.personal ?? [];
      let x = 1;
      for (const element of personal) {
        this.processMapData(element.name, x, 1);
        this.processMapData(element.bod, x, 2);
        this.processMapData(element.address, x, 3);
        this.processMapData(element.employer, x, 4);
        x++;
      }
    },

    processMapData(data, bureau_id, type) {
      if (typeof data == "object") {
        data.map((value) => {
          if (value != "") {
            this.personal_info.push({
              type: type,
              content: value,
              status: 5,
              bureau_id: bureau_id,
              check: false,
              for_edit: false,
            });
          }
        });
      } else if (data != "") {
        this.personal_info.push({
          type: type,
          content: data,
          status: 5,
          bureau_id: bureau_id,
          check: false,
          for_edit: false,
        });
      }
    },

    isValidStatusNumber(data) {
      return (
        (data.a_status.match(/-|--/) == null &&
          data.a_description.match(/-|--/) == null) ||
        data.a_number.length > 3
      );
    },
    buildArrayAccounts() {
      let account = this.data[0]?.account ?? [];
      account.map((item) => {
        const IsNumeric = (num) => /^-{0,1}\d*\.{0,1}\d+$/.test(num);
        let elements = [];
        let showAccount = false;
        item.map((value) => {
          if (this.isValidStatusNumber(value)) {
            showAccount = true;
          }
        });
        if (showAccount) {
          item.map((value, index) => {
            let commentary = "";
            if (typeof value.comments == "object") {
              value.comments.map((comment) => {
                commentary += comment + " ";
              });
            } else {
              commentary = value.comments;
            }
            elements.push({
              content: this.isValidStatusNumber(value) ? value.a_name : "-",
              a_number: value.a_number,
              balance: IsNumeric(String(value.balance).replace(/[$,]/g, ""))
                ? value.balance
                : 0,
              type: value.a_type,
              status: value.negative ? 6 : 5,
              bureau_id: index + 1,
              p_status: value.p_status,
              c_limit: value.c_limit == "-" ? 0 : value.c_limit,
              monthly: value.monthly == "-" ? 0 : value.monthly,
              last_p: value.last_p == "-" ? 0 : value.last_p,
              a_status: value.a_status,
              a_description: value.a_description,
              date: value.date,
              n_months: value.n_months,
              h_credit: value.h_credit,
              comments: commentary,
              lp_status: value.lp_status,
              isShow: this.isValidStatusNumber(value),
              edit: false,
              round: null,
              type_of_disput: null,
              reason: null,
              instruction: null,
              classification: value.classification,
              edit_classification: false,
              results: value.results,
              negative: value.negative,
              to_select: false,
              status_dispute: value.status_dispute,
              new_account: 0,
              a_type_detail: value.a_type_detail,
            });
          });
          this.accounts.push({
            element: elements,
          });
          this.oldAccounts = JSON.parse(JSON.stringify(this.accounts));
        }
      });
    },

    async processReport() {
      let foundValidClassification = false;

      this.accounts.forEach((item) => {
        item.element.forEach((el) => {
          if (el.status == 6 && el.classification == null) {
            foundValidClassification = true;
          }
        });
      });

      this.validClassifications = !foundValidClassification;

      if (!this.validClassifications) {
        this.$swal({
          title: "IMPORTANT!",
          text: "Please classify all accounts in negative.",
          icon: "info",
        });
      } else {
        this.addPreloader();
        try {
          await ClientsOptionsServices.deleteForException({
            id_user: this.currentUser.user_id,
            account_id: this.$route.params.accountId,
            type: 1,
          });
          await amgApi.post("/lead/credit-report/process-report", {
            user_id: this.currentUser.user_id,
            account: this.$route.params.accountId,
            id_request: this.$route.params.idSection,
            score_id: this.idfile,
            pi: this.personal_info,
            in: this.inquires,
            pr: this.public_info,
            ac: this.accounts,
            scores: this.datos.score,
          });
          this.personal_info = [];
          this.inquires = [];
          this.public_info = [];
          this.accounts = [];
          await this.generateReport();
        } catch (error) {
          console.log(error);
        } finally {
          this.removePreloader();
        }
      }
    },

    addAccount() {
      let elements = [];
      for (let i = 0; i < 3; i++) {
        elements.push({
          content: "-",
          a_number: "-",
          balance: 0,
          type: "-",
          status: 5,
          bureau_id: i + 1,
          p_status: "-",
          c_limit: 0,
          monthly: 0,
          last_p: 0,
          a_status: "-",
          a_description: "-",
          date: "-",
          n_months: "-",
          h_credit: "-",
          comments: "-",
          isShow: true,
          edit: false,
          round: null,
          to_select: false,
          classification: null,
          edit_classification: false,
          lp_status: "-",
        });
      }
      this.accounts.push({
        element: elements,
      });
      this.oldAccounts = JSON.parse(JSON.stringify(this.accounts));
    },

    addPublicRecord() {
      let elements = [];
      for (let i = 0; i < 3; i++) {
        elements.push({
          content: null,
          status: 6,
          bureau_id: i + 1,
          court: null,
          date: null,
          reference: null,
          closing: null,
          status_show: null,
          round: null,
          edit: false,
        });
      }
      this.public_info.push({
        element: elements,
        date: null,
      });
      this.oldInfo = JSON.parse(JSON.stringify(this.public_info));
    },

    alltitles() {
      amgApi.get("/lead/ncr/all-titles").then((res) => {
        this.titles = res.data;
      });
    },

    allFormats() {
      amgApi.get("/lead/ncr/all-formats").then((res) => {
        this.formats = res.data;
      });
    },

    reloadHeaders() {
      if (this.isForRound) {
        this.headerAccounts.push({
          key: "round",
          label: "Round",
        });
        this.headerPublics.push({
          key: "round",
          label: "Round",
        });
      }
    },

    async toRevision(data) {
      if (this.G_DISPUTE_DATA.length == 0) {
        const validateObservation = await this.$swal({
          title: "Your Observation",
          input: "textarea",
          showCancelButton: true,
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",

          inputValidator: (observation) => {
            // If the value is valid, you must return undefined. Otherwise, a string
            return !observation ? "Please enter your observation" : undefined;
          },
        });
        if (validateObservation.value) {
          // const swal = await this.showConfirmSwal();
          // if (swal.isConfirmed) {
          this.A_CLEAR_DISPUTE_DATA([]);
          this.showQuestionSwal(
            "Would you like to recommend a service?",
            null,
            "Yes",
            "Cancel",
            "question",
            {
              allowEscapeKey: false,
              allowOutsideClick: false,
              showDenyButton: true,
              showCancelButton: true,
              denyButtonText: "No",
              customClass: {
                confirmButton: "btn btn-outline-success mr-1",
                cancelButton: "btn btn-outline-warning mr-1",
                denyButton: "btn btn-outline-danger  mr-1",
              },
              focusConfirm: false,
            }
          ).then(async (result) => {
            if (result.isConfirmed) {
              this.addPreloader();
              this.comment = validateObservation.value;
              this.showModalRecommend = true;
            } else if (result.isDenied) {
              this.addPreloader();
              const params = {
                save_by: this.currentUser.user_id,
                letters_id: this.round_id,
                id: this.idSection,
                comment: validateObservation.value,
              };
              if (this.section == "update") {
                const res = await DisputeResultsService.toRevisionUpdateRequest(
                  params
                );
                if (res.status == 200) {
                  this.showSuccessSwal(res.message);
                  this.$emit("reload");
                  if (this.currentRoute == "report-lead-specialists-ad-cr") {
                    this.$router.push({
                      name: "sp-update-request-to-revision",
                    });
                  }
                  this.A_CLEAR_DISPUTE_DATA([]);
                }
              } else if (this.section == "workplan") {
                const res = await ReportService.toRevision(params);
                if (res.status == 200) {
                  this.showSuccessSwal("Request Workplan completed");
                  if (this.currentRoute == "report-lead-specialists-ad-cr") {
                    this.$router.push({ name: "sp-wp-to-revision" });
                  }
                } else {
                  this.showErrorSwal();
                }
              }
            }
            this.removePreloader();
          });
          this.removePreloader();
          // }
        }
      } else {
        this.showModalRound = true;
      }
    },

    async returnPage() {
      confirm = await this.showConfirmSwal(
        "Are you sure you want to return to the previous page?",
        "You'll lose all the information you just registered"
      );
      if (!confirm.isConfirmed) return;
      this.$router.go(-1);
    },
    async getClientWpReport() {
      try {
        const { data } = await ReportService.getClientWpReport({
          id: this.$route.params.idSection,
          section: this.$route.params.section,
        });
        this.requestWpdataClient = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getUpdateFiles() {
      try {
        const { data } = await ReportLeadService.getUpdateFilesPending(
          this.accountId
        );
        this.updateFiles = data;
      } catch (error) {
        console.log(error);
      }
    },
    showAccessCr() {
      if (this.showFiles && this.gridName == "showFiles") {
        this.showFiles = true;
        this.gridName = "showAccessCr";
      } else if (this.showFiles && this.gridName != "showFiles") {
        this.showFiles = false;
        this.gridName = "showInfoMain";
      } else {
        this.showFiles = true;
        this.gridName = "showAccessCr";
      }
    },
    showFilesCollapsed() {
      if (this.showFiles && this.gridName == "showAccessCr") {
        this.showFiles = true;
        this.gridName = "showFiles";
      } else if (this.showFiles && this.gridName != "showAccessCr") {
        this.showFiles = false;
        this.gridName = "showInfoMain";
      } else {
        this.showFiles = true;
        this.gridName = "showFiles";
      }
    },
    closeModalApps() {
      this.openModalAppsController = false;
    },
    openModalApps() {
      this.openModalAppsController = true;
    },
    closeFirstNoteModal() {
      this.firstNoteController = false;
    },
    async openFirstNoteModal() {
      await this.setData();
      this.firstNoteController = true;
    },
    async setData() {
      return new Promise((resolve) => {
        this.noteInfo = {
          id: this.client.id,
          nameProgram: this.client.program_name,
          created: this.client.creates,
          nameClient: this.client.client_name,
          saleId: this.client.sale_id,
          sourcesName: this.client.sourcesname,
          pack: this.client.pack,
          capturedName: this.client.captured_name,
          sellerName: this.client.seller_name,
          module: this.client.module,
          clientType: this.client.client_type_id,
        };
        if ([1, 2, 3, 5, 7].includes(this.client.program_id)) {
          this.programId = this.client.program_id;
        }
        resolve();
      });
    },
  },
};
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
}
.table::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.table::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  /* border-radius: 7px; */
}
.table::-webkit-scrollbar-thumb:hover {
  background: #c5c2c2 !important;
  /* border-radius: 10px; */
}
.border-danger {
  border-color: #dc3545 !important;
}
</style>
<style>
.align_checkbox .custom-control-label::before,
.align_checkbox .custom-control-label::after {
  left: 0px !important;
}
</style>

<style scoped>
.title-process {
  font-size: 2rem;
  font-weight: 600;
}
.title-platform {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .title-process {
    font-size: 1.5rem;
  }
  .title-platform {
    font-size: 1rem;
  }
}

.fixed-top-component {
  position: sticky;
  position: -webkit-sticky;
  top: 80px;
  z-index: 999;
}
</style>
