<template>
  <b-card class="mb-1">
    <div class="mb-1 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center justify-content-between">
        <feather-icon icon="CircleIcon" size="15" class="color-inquires" />
        <span class="title-table d-inline-block lh-0 mr-1">Accounts </span>
        <b-badge
          class="cursor-pointer"
          variant="light-primary"
          :class="showAccounts ? null : 'collapsed'"
          @click="showAccounts = !showAccounts"
          aria-controls="collapse-inquires"
          :aria-expanded="showAccounts"
        >
          {{ showAccounts ? "Hide" : "Show" }}
        </b-badge>
      </div>
    </div>
    <b-collapse v-model="showAccounts" id="collapse-inquires">
      <b-table-simple
        small
        caption-top
        responsive
        bordered
        class="position-relative custom-table-report"
        :sticky-header="'65vh'"
      >
        <b-thead>
          <b-tr>
            <b-th class="td-left text-right header-width"></b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">TransUnion</span>
              <b-img :src="require('@/assets/images/icons/transunion.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Experian</span>
              <b-img :src="require('@/assets/images/icons/experian.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Equifax</span>
              <b-img :src="require('@/assets/images/icons/equifax.png')" />
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(item, indexAccount) in accounts">
            <b-tr class="bg-dark" :key="item.id">
              <b-th>
                <div class="d-flex justify-content-between">
                  <b-badge
                    class="cursor-pointer"
                    variant="danger"
                    v-if="isForSpecialist"
                    @click="removeAccount(indexAccount)"
                  >
                    <feather-icon icon="TrashIcon" size="13" />
                    Remove Account
                  </b-badge>

                  <b-badge
                    class="cursor-pointer"
                    variant="warning"
                    v-if="isForSpecialist && !item.element[0].new_account"
                    @click="markNewAccount(item)"
                  >
                    <feather-icon icon="PlusIcon" size="13" />
                    Mark as New Account
                  </b-badge>
                  <b-badge
                    class="cursor-pointer"
                    variant="info"
                    v-if="isForSpecialist && item.element[0].new_account"
                    @click="markNewAccount(item)"
                  >
                    <feather-icon icon="MinusIcon" size="13" />
                    Remove New Account
                  </b-badge>

                  <b-badge
                    variant="info"
                    v-if="item.element[0].new_account == 1"
                  >
                    New Account
                  </b-badge>
                </div>
              </b-th>
              <template v-for="t in 3">
                <b-th
                  class="font-weight-bolder col-3 text-center bg-dark"
                  :key="t - 1"
                >
                  <template v-if="isForSpecialist">
                    <b-badge
                      class="cursor-pointer"
                      variant="info"
                      @click="
                        (item.element[t - 1].edit = !item.element[t - 1].edit),
                          editAccount(1, t - 1, indexAccount)
                      "
                      v-if="!item.element[t - 1].edit"
                    >
                      <feather-icon icon="EditIcon" size="13" />
                      Edit Account
                    </b-badge>
                    <div v-else>
                      <b-badge
                        class="cursor-pointer mr-1"
                        variant="danger"
                        @click="
                          (item.element[t - 1].edit =
                            !item.element[t - 1].edit),
                            editAccount(0, t - 1, indexAccount)
                        "
                      >
                        <feather-icon icon="XIcon" size="13" />
                        Cancel
                      </b-badge>
                      <b-badge
                        class="cursor-pointer"
                        variant="success"
                        @click="
                          item.element[t - 1].edit = !item.element[t - 1].edit
                        "
                      >
                        <feather-icon icon="SaveIcon" size="13" />
                        Save
                      </b-badge>
                    </div>
                  </template>
                  <template v-if="isForRound">
                    <div class="d-flex justify-content-center">
                      <b-form-checkbox
                        v-model="item.element[t - 1].to_select"
                        v-if="
                          item.element[t - 1].status != 1 &&
                          isValidStatusNumber(item.element[t - 1])
                        "
                        style="margin: 5px 0"
                        class="font-weight-bolder select-color"
                        @change="toSelected(item, [t - 1])"
                        >TO SELECT
                        <!-- &&
                          (arrAcc.includes(item.element[t - 1].id) ||
                            arrAcc.length == 0) -->
                      </b-form-checkbox>
                      <div
                        v-else-if="item.element[t - 1].status == 1"
                        class="d-flex align-items-center font-weight-bolder"
                      >
                        <span class="text-warning">IN DISPUTE</span>
                        <template>
                          <b-badge
                            class="ml-1"
                            style="padding: 1px 3px"
                            :variant="
                              item.element[t - 1].type_send == 'CERTIFIED'
                                ? 'primary'
                                : 'info'
                            "
                          >
                            <template
                              v-if="item.element[t - 1].type_send == 'PHONE'"
                            >
                              <feather-icon
                                icon="PhoneIcon"
                                size="18"
                                class="icon-style"
                                v-b-tooltip.hover.top="'Direct'"
                              />
                              <p class="d-inline" style="font-size: 10px">
                                {{ item.element[t - 1].type_send }}
                              </p>
                            </template>

                            <template v-else>
                              <amg-icon
                                class="cursor-deafult icon-style"
                                icon="LetterIcon"
                                size="20"
                                v-b-tooltip.hover.top="'Letter'"
                              />
                              <p class="d-inline" style="font-size: 10px">
                                {{
                                  getRoundNumber(item.element[t - 1].title_id)
                                }}
                              </p>
                            </template>
                          </b-badge>
                        </template>

                        <template>
                          <b-badge
                            class="ml-1"
                            style="padding: 1px 3px"
                            :variant="
                              item.element[t - 1].status_dispute == 'PENDING'
                                ? 'primary'
                                : 'info'
                            "
                          >
                            <template
                              v-if="
                                item.element[t - 1].status_dispute == 'PENDING'
                              "
                            >
                              <tabler-icon
                                icon="ClockIcon"
                                size="20"
                                class="icon-style"
                                v-b-tooltip.hover.top="'Direct'"
                              />
                              <p class="d-inline" style="font-size: 10px">
                                {{ item.element[t - 1].status_dispute }}
                              </p>
                            </template>
                            <template
                              v-else-if="
                                item.element[t - 1].status_dispute == 'SENT'
                              "
                            >
                              <feather-icon
                                icon="SendIcon"
                                size="20"
                                class="icon-style"
                                v-b-tooltip.hover.top="'Direct'"
                              />
                              <p class="d-inline" style="font-size: 10px">
                                {{ item.element[t - 1].status_dispute }}
                              </p>
                            </template>
                          </b-badge>
                        </template>
                      </div>
                    </div>
                  </template>
                </b-th>
              </template>
            </b-tr>
            <b-tr
              v-for="(header, indexHeader) in headerAccounts"
              :key="header.key + indexAccount"
              :ref="
                header.key != 'detail' &&
                header.key != 'status' &&
                header.key != 'round'
                  ? 'trAccounts' + indexAccount
                  : 'trAccounts' + 1000000000
              "
              :class="{
                'd-none':
                  indexHeader > 3 &&
                  header.key != 'detail' &&
                  header.key != 'status' &&
                  header.key != 'round',
              }"
            >
              <b-td
                class="td-left text-left"
                v-if="
                  header.key == 'round' &&
                  item.element.filter((item) => item.to_select).length > 0
                "
              >
              </b-td>
              <b-td
                v-else
                :class="
                  header.key == 'status'
                    ? 'bg-gray font-weight-bolder  v-middle'
                    : ''
                "
              >
                <div v-if="header.key == 'detail'">
                  <div class="text-center">
                    <b-badge
                      variant="secondary"
                      class="cursor-pointer"
                      @click="toggleDetails(indexAccount)"
                    >
                      <span :ref="'btnAccounts' + indexAccount">
                        {{ header.label }}
                      </span>
                      <feather-icon icon="ChevronRightIcon" size="15" />
                    </b-badge>
                  </div>
                </div>
                <div v-else class="text-right">
                  <div class="font-weight-bolder">{{ header.label }}</div>
                  <div
                    v-if="header.label == 'Account Name'"
                    class="text-danger font-weight-bolder"
                  >
                    <div
                      v-if="
                        item.element[0].status == 6 ||
                        item.element[1].status == 6 ||
                        item.element[2].status == 6
                      "
                    >
                      <div
                        v-if="visibleAddClassification(item) && process != 1"
                      >
                        <b-badge
                          class="cursor-pointer"
                          variant="info"
                          @click="OpenEditClassification(item.element)"
                        >
                          <feather-icon icon="PlusIcon" size="20" />
                          ADD CLASSIFICATION
                        </b-badge>
                      </div>
                      <div v-if="!visibleAddClassification(item)">
                        <b-badge
                          class="d-inline"
                          style="width: 100px"
                          variant="light-danger"
                          v-if="!item.element[0].edit_classification"
                        >
                          {{
                            item.element[0].classification ||
                            item.element[1].classification ||
                            item.element[2].classification
                          }}
                        </b-badge>
                        <div class="d-inline">
                          <b-form-select
                            class="w-75"
                            v-if="item.element[0].edit_classification"
                            v-model="selectedClassification"
                            :options="optionsClassification"
                            size="sm"
                          ></b-form-select>
                        </div>
                        <feather-icon
                          v-if="process != 1"
                          :icon="
                            item.element[0].edit_classification
                              ? 'XCircleIcon'
                              : 'EditIcon'
                          "
                          class="d-inline cursor-pointer"
                          :class="
                            item.element[0].edit_classification
                              ? 'text-danger'
                              : 'text-warning'
                          "
                          style="margin: 0 0 2px 5px"
                          v-b-tooltip.hover.top="
                            item.element[0].edit_classification
                              ? 'Cancel'
                              : 'Edit Classification'
                          "
                          size="16"
                          @click="OpenEditClassification(item.element)"
                        />
                        <feather-icon
                          v-if="item.element[0].edit_classification"
                          :icon="'SaveIcon'"
                          class="d-inline cursor-pointer"
                          style="margin: 0 0 2px 5px"
                          v-b-tooltip.hover.top="
                            selectedClassification.id == null
                              ? 'Select an option'
                              : 'Save'
                          "
                          size="16"
                          @click="EditClassification(item.element)"
                          :class="
                            selectedClassification.id == null
                              ? 'cursor-not-allowed text-warning'
                              : 'cursor-pointer  text-success'
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-td>

              <template v-for="(value, indexItem) in item.element">
                <template v-for="(valueItem, key, indexValue) in value">
                  <b-td
                    :key="indexValue + indexItem"
                    v-if="header.key == key"
                    class="col-3 text-center"
                    :class="`${
                      (value.status == 6 || value.status == 2) &&
                      value.isShow &&
                      header.key != 'status' &&
                      header.key != 'round' &&
                      isDarkSkin == true
                        ? !completeDispute(value)
                          ? 'bg-warning'
                          : 'bg-danger bg-light-red2'
                        : (value.status == 6 || value.status == 2) &&
                          value.isShow &&
                          header.key != 'status' &&
                          header.key != 'round' &&
                          isDarkSkin == false
                        ? 'bg-light-red'
                        : value.status == 5 &&
                          value.isShow &&
                          header.key != 'status' &&
                          header.key != 'round' &&
                          isDarkSkin == true
                        ? !completeDispute(value)
                          ? 'bg-warning'
                          : 'bg-primary'
                        : value.status == 5 &&
                          value.isShow &&
                          header.key != 'status' &&
                          header.key != 'round' &&
                          isDarkSkin == false
                        ? 'bg-light-primary'
                        : (value.status == 4 || value.status == 3) &&
                          value.isShow &&
                          header.key != 'status' &&
                          header.key != 'round' &&
                          isDarkSkin == true
                        ? 'bg-success '
                        : (value.status == 4 || value.status == 3) &&
                          value.isShow &&
                          header.key != 'status' &&
                          header.key != 'round' &&
                          isDarkSkin == false
                        ? 'bg-light-success'
                        : value.status == 1 &&
                          value.isShow &&
                          header.key != 'status' &&
                          header.key != 'round' &&
                          isDarkSkin == true
                        ? 'bg-warning-rp'
                        : value.status == 1 &&
                          value.isShow &&
                          header.key != 'status' &&
                          header.key != 'round' &&
                          isDarkSkin == false
                        ? 'bg-light-warning'
                        : header.key == 'status'
                        ? 'bg-gray'
                        : ''
                    } ${statusValue}`"
                  >
                    <v-select
                      v-if="header.key == 'status' && value.a_number.length > 3"
                      :disabled="
                        disableModule || (isForRound && section != 'update')
                      "
                      :options="status"
                      :reduce="(val) => val.id"
                      label="value"
                      v-model="value.status"
                      :calculate-position="withPopper"
                      @close="handleSelectClose(value)"
                      @option:selecting="handleOptionSelecting(value)"
                    ></v-select>
                    <span
                      v-else-if="header.key != 'status' && !value.edit"
                      :class="isDarkSkin == true ? 'text-white' : ''"
                    >
                      <span
                        v-if="
                          process == 1 &&
                          (key == 'monthly' ||
                            key == 'balance' ||
                            key == 'c_limit')
                        "
                        >$ {{ Number(valueItem) | currency }}</span
                      >
                      <span
                        v-else-if="
                          [
                            'h_credit',
                            'c_limit',
                            'balance',
                            'monthly',
                          ].includes(key)
                        "
                        >$ {{ valueItem }}</span
                      >
                      <span v-else>{{ valueItem }}</span>
                    </span>
                    <b-input
                      v-else-if="
                        header.key != 'status' &&
                        header.key != 'round' &&
                        value.edit
                      "
                      :type="header.key == 'date' ? 'date' : 'text'"
                      v-model="value[key]"
                      :disabled="disableModule"
                    />

                    <div v-if="header.key == 'round' && value.to_select">
                      <template>
                        <div v-if="disputeCounter >= 0">
                          <b-button
                            v-if="completeDispute(value)"
                            @click="openDisputeDetailsModal(value)"
                            class="my-2"
                            variant="info"
                            :disabled="shouldDisableDisputeButton(value)"
                          >
                            ADD NEW ROUND
                          </b-button>
                          <b-button
                            v-else
                            class="my-2 cursor-not-allowed"
                            variant="primary"
                            :disabled="true"
                          >
                            IN ROUND
                          </b-button>
                        </div>
                      </template>
                    </div>
                  </b-td>
                </template>
              </template>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-collapse>

    <dispute-details-modal
      v-if="showDisputeDetailsModal"
      :selectedElements="selectedElements"
      :isAccount="true"
      @resetSelectedElem="resetSelectedElements"
      @close="closeDisputeDetailsModal()"
    />
  </b-card>
</template>

<script>
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import DisputeDetailsModal from "@/views/commons/components/request-workplan/views/components/modal/DisputeDetailsModal.vue";
import { mapGetters, mapActions } from "vuex";
import { createPopper } from "@popperjs/core";
import ReportLeadService from "@/views/commons/components/ncr/components/report/service/report.service";

export default {
  components: {
    DisputeDetailsModal,
  },
  mounted() {
    this.allStatus();
    this.reloadHeaders();
  },
  props: {
    accounts: {
      required: true,
    },
    day_processed: {
      required: true,
    },
    process: {
      required: true,
    },
    round_id: {
      required: true,
    },
    idSection: {
      required: false,
    },
    section: {
      type: String,
      default: "workplan",
      validator: function (value) {
        return ["workplan", "update"].includes(value);
      },
    },
    rowData: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {
      disputeCounter: 0,
      placement: "top",
      selectedElements: [],
      selectedElementIndex: null,
      showDisputeDetailsModal: false,
      // accountDetail: "",
      status: [],
      showAccounts: true,
      headerAccounts: [
        {
          key: "content",
          label: "Account Name",
          classification: null,
        },
        {
          key: "a_number",
          label: "Account #",
        },
        {
          key: "type",
          label: "Account Type",
        },
        {
          key: "a_status",
          label: "Account Status",
        },
        {
          key: "a_description",
          label: "Account Description",
        },
        {
          key: "monthly",
          label: "Monthly Payment",
        },
        {
          key: "date",
          label: "Date Opened",
        },
        {
          key: "balance",
          label: "Balance",
        },
        {
          key: "n_months",
          label: "No. of Months (terms)",
        },
        {
          key: "h_credit",
          label: "Highest Credit",
        },
        {
          key: "c_limit",
          label: "Credit Limit",
        },
        {
          key: "p_status",
          label: "Payment Status",
        },
        {
          key: "comments",
          label: "Comments",
        },
        {
          key: "lp_status",
          label: "Last Payment Status",
        },
        {
          key: "detail",
          label: "Show More Details",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
      setAccounts: {
        reason: [],
        instruction: [],
      },
      validate: null,
      arrAcc: [],
      selectedClassification: { id: null, label: "Please select an option" },
      optionsClassification: [
        {
          value: { id: null, label: "Please select an option" },
          text: "Please select an option",
          disabled: true,
        },
      ],
      isEditClassification: false,
      oldAccounts: [],
      originalStatus: null,
    };
  },
  methods: {
    ...mapActions({
      A_REMOVE_ACCOUNT: "RequestWorkPlanStore/A_REMOVE_ACCOUNT",
    }),

    openDisputeDetailsModal() {
      this.showDisputeDetailsModal = true;
    },

    closeDisputeDetailsModal() {
      this.showDisputeDetailsModal = false;
    },

    async OpenEditClassification(item) {
      item.forEach((el) => {
        el.edit_classification = !el.edit_classification;
      });

      if (item[0].edit_classification) {
        const data = await RequestWorkPlanService.getOnlyClassifications();
        this.optionsClassification = [
          {
            value: { id: null, label: "Please select an option" },
            text: "Please select an option",
            disabled: true,
          },
        ];

        data.forEach((item) => {
          this.optionsClassification.push({
            value: { id: item.id, label: item.name },
            text: item.name,
          });
        });
      }
      this.selectedClassification = {
        id: null,
        label: "Please select an option",
      };
    },

    EditClassification(item) {
      if (this.selectedClassification.id == null) return;
      item.forEach((el) => {
        el.classification = this.selectedClassification.label;
        el.edit_classification = false;
      });

      this.selectedClassification = {
        id: null,
        label: "Please select an option",
      };
    },

    setDataOptions(data, options) {
      data.forEach((item) => {
        options.push({
          value: { id: item.id, label: item.name },
          text: item.name,
        });
      });
    },

    allStatus() {
      amgApi
        .get("/lead/get-all-status-letters")
        .then((response) => {
          if (response.status == 200) {
            this.status = response.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    isValidStatusNumber(data) {
      return (
        (data.a_status &&
          data.a_status.match(/-|--/) == null &&
          data.a_description &&
          data.a_description.match(/-|--/) == null) ||
        (data.a_number && data.a_number.length > 3)
      );
    },
    toggleDetails(index) {
      this.$refs[`trAccounts${index}`].map((item, index) => {
        if (index > 3 && index != 1000000000) {
          item.$el.classList.toggle("d-none");
        }
      });
      let nameButton = this.$refs[`btnAccounts${index}`][0].innerText;
      this.$refs[`btnAccounts${index}`][0].innerText =
        nameButton.trim() == "Show More Details"
          ? "Hide Details"
          : "Show More Details";
    },
    removeAccount(index) {
      this.accounts.splice(index, 1);
      this.oldAccounts = JSON.parse(JSON.stringify(this.accounts));
    },
    editAccount(action, index, indexAccount) {
      if (action == 0) {
        this.accounts[indexAccount].element[index] =
          this.oldAccounts[indexAccount].element[index];
        this.accounts[indexAccount].element[index].edit = false;
        let band = true;
        this.accounts[indexAccount].element.map((item) => {
          if (item.edit) {
            band = false;
          }
        });
        if (band) {
          this.oldAccounts = JSON.parse(JSON.stringify(this.accounts));
        }
      } else {
        this.oldAccounts = JSON.parse(JSON.stringify(this.accounts));
      }
    },
    removeAllAccount() {
      this.accounts = [];
    },
    visibleAddClassification(item) {
      return (
        item.element.every((i) => i.classification == null) &&
        item.element.every((i) => !i.edit_classification)
      );
    },
    addAccount() {
      let elements = [];
      for (let i = 0; i < 3; i++) {
        elements.push({
          content: "-",
          a_number: "-",
          balance: 0,
          type: "-",
          status: 5,
          bureau_id: i + 1,
          p_status: "-",
          c_limit: 0,
          monthly: 0,
          last_p: 0,
          a_status: "-",
          a_description: "-",
          date: "-",
          n_months: "-",
          h_credit: "-",
          comments: "-",
          isShow: true,
          edit: false,
          round: null,
          to_select: false,
          edit_classification: false,
          classification: null,
          results: null,
          negative: null,
          lp_status: "-",
        });
      }
      this.accounts.push({
        element: elements,
      });
      this.oldAccounts = JSON.parse(JSON.stringify(this.accounts));
    },
    shouldDisableDisputeButton({ id }) {
      if (this.selectedElementIndex == null) {
        this.selectedElementIndex = id;
      }

      if (this.selectedElements.length > 0) {
        let lastSelected =
          this.selectedElements[this.selectedElements.length - 1];
        if (lastSelected.id == id) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },

    resetSelectedElements() {
      this.disputeCounter++;
      this.selectedElementIndex = null;
      this.selectedElements = [];
      this.arrAcc = [];
    },

    completeDispute(item_id) {
      let bool = true;
      this.G_DISPUTE_DATA.forEach((el) => {
        if (el.id == item_id.id) {
          bool = false;
        }
      });
      return bool;
    },

    toSelected(item, index) {
      if (item.element[index].to_select) {
        item.element[index].round_id = this.round_id;
        item.element[index].type_element = 4;
        this.selectedElements.push(item.element[index]);
        if (this.arrAcc.length == 0) {
          item.element.forEach((el) => {
            this.arrAcc.push(el.id);
          });
        }
      }
      if (!item.element[index].to_select) {
        this.A_REMOVE_ACCOUNT(item.element[index].id);
        this.selectedElementIndex = null;
        this.selectedElements = this.selectedElements.filter(
          (element) => element.id != item.element[index].id
        );
        if (this.selectedElements.length == 0) this.arrAcc = [];
      }
      // data
      let count = 0;
      item.element.map((items) => {
        if (items.to_select == 1) {
          count = count + 1;
        }
      });
      if (count > 0) {
        this.btnRound = true;
      }
      this.$emit("setAccounts", this.setAccounts);
    },
    reloadHeaders() {
      if (this.isForRound) {
        this.headerAccounts.push({
          key: "round",
          label: "Round",
        });
      }
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },

    getRoundNumber(action) {
      switch (action) {
        case 1:
          return "FIRST ROUND";
        case 2:
          return "SECOND ROUND";
        case 3:
          return "THIRD ROUND";
        case 4:
          return "FOURTH ROUND";
        default:
          return "FIFTH ROUND";
      }
    },

    markNewAccount(item) {
      item.element.forEach((el) => {
        this.$set(el, "new_account", !el.new_account);
      });
    },
    handleOptionSelecting(data) {
      if (this.section == "update") {
        this.originalStatus = data.status;
      }
    },
    async handleSelectClose(data) {
      if (this.section == "update") {
        try {
          const params = {
            id: data.id,
            status: data.status,
            user_id: this.currentUser.user_id,
            update_id: this.idSection,
            client_account_id: this.rowData.client_account_id,
          };
          if (data.status == this.originalStatus) {
            return;
          }
          const result = await ReportLeadService.updateAccountIndividual(
            params
          );
          if (result.status == 200) {
            this.originalStatus = data.status;
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
          } else {
            this.showToast("danger", "top-right", "Oop!", "AlertOctagonIcon");
            this.$nextTick(function () {
              data.status = this.originalStatus;
            });
          }
        } catch (error) {
          data.status = this.originalStatus;
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      G_DISPUTE_DATA: "RequestWorkPlanStore/G_DISPUTE_DATA",
      currentUser: "auth/currentUser",
    }),
    isSpecialist() {
      return [28, 29, 30].includes(this.moduleId);
    },
    isForSpecialist() {
      return (
        (this.process != 1 ||
          (this.process == 1 && this.section == "update")) &&
        this.isSpecialist
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isForRound() {
      return (
        this.process == 1 &&
        this.moduleId == 28 &&
        this.day_processed > "2021-09-27"
      );
    },
    statusValue() {
      return this.skin == "dark" ? "text-primary" : "";
    },
    disableModule() {
      return this.moduleId != 28;
    },
    idBureau() {
      return this.$route.params.idBureau;
    },
  },
  watch: {
    isForRound(value) {
      if (value) {
        this.reloadHeaders();
      }
    },

    accounts: {
      handler(value) {
        value.forEach((item) => {
          if (
            item.element[0].status != 6 &&
            item.element[1].status != 6 &&
            item.element[2].status != 6
          ) {
            item.element[0].classification = null;
            item.element[1].classification = null;
            item.element[2].classification = null;
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.bg-light-red {
  background: #ffb2c5 !important;
  color: #111013 !important;
}

.title-table {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.color-inquires {
  color: #ff0000;
  fill: #ff0000;
  margin-right: 10px;
}
.icon-style {
  margin-top: 3px;
}
</style>

<style lang="scss">
.bg-warning-rp {
  background-color: #ff9f43;
  color: #111013 !important;
  span {
    color: #111013 !important;
  }
}
.select-color {
  .custom-control-label {
    color: white !important;
    font-weight: bold !important;
  }
}
</style>

<style lang="scss" scoped>
.custom-table-report::v-deep thead tr th {
  background-color: #3a72ea !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 13px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.custom-table-report::v-deep thead tr th:first-child {
  border-radius: 15px 0 0 0 !important;
}

.custom-table-report::v-deep thead tr th:last-child {
  border-radius: 0 15px 0 0 !important;
}
</style>
