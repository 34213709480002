<template>
  <b-modal
    modal
    v-model="showModal"
    no-close-on-backdrop
    size="xlg"
    header-class="b-vue-modal-header"
    modal-class="modal-primary"
    title="STRATEGIES"
    title-class="h3 text-white font-weight-bolder"
    centered
    footer-class="justify-content-center"
    @hidden="closeModal"
  >
    <b-container fluid>
      <b-row class="mb-3 p-1 fw-bold custom-input">
        <h3 class="fw-bold text-center text-primary col-12">
          CLIENT INFORMATION
        </h3>
        <b-col lg="4" class="mt-1">
          <b-input-group>
            <b-input-group-prepend class="w-35">
              <b-input-group-text class="w-100">
                <feather-icon icon="LayersIcon" class="mr-1" />MODULE
              </b-input-group-text>
            </b-input-group-prepend>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />
            <b-form-input
              v-if="finishedLoading"
              :value="dataClient.program_name"
              :disabled="true"
            />
          </b-input-group>
        </b-col>
        <b-col lg="4" class="mt-1">
          <b-input-group>
            <b-input-group-prepend class="w-35">
              <b-input-group-text class="w-100">
                <feather-icon icon="UserIcon" class="mr-1" />CLIENT
              </b-input-group-text>
            </b-input-group-prepend>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />
            <b-form-input
              v-if="finishedLoading"
              :value="dataClient.client_name"
              :disabled="true"
            />
          </b-input-group>
        </b-col>
        <b-col lg="4" class="mt-1">
          <b-input-group>
            <b-input-group-prepend class="w-35">
              <b-input-group-text class="w-100">
                <feather-icon icon="UserIcon" class="mr-1" />TYPE CLIENT
              </b-input-group-text>
            </b-input-group-prepend>

            <b-skeleton v-if="!finishedLoading" class="space" type="input" />
            <b-form-input
              v-if="finishedLoading"
              :value="dataClient.type_client == 1 ? 'NEW CLIENT' : 'REGULAR'"
              :disabled="true"
            />
          </b-input-group>
        </b-col>
        <b-col lg="4" class="mt-1">
          <b-input-group>
            <b-input-group-prepend class="w-35">
              <b-input-group-text class="w-100">
                <feather-icon icon="PackageIcon" class="mr-1" />ACCOUNT
              </b-input-group-text>
            </b-input-group-prepend>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />
            <b-form-input
              v-if="finishedLoading"
              :value="dataClient.account"
              :disabled="true"
            />
          </b-input-group>
        </b-col>
        <b-col lg="4" class="mt-1">
          <b-input-group>
            <b-input-group-prepend class="w-35">
              <b-input-group-text class="w-100">
                <feather-icon icon="UserCheckIcon" class="mr-1" />AGENT/ADVISOR
              </b-input-group-text>
            </b-input-group-prepend>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />
            <b-form-input
              class="text-white"
              v-if="finishedLoading"
              :value="dataClient.advisor_name"
              :disabled="true"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <ValidationObserver ref="form">
            <b-nav card-header pills class="m-0">
              <b-nav-item
                active
                :link-classes="['px-3', bgTabsNavs]"
                class="fw-bold"
              >
                Rounds
              </b-nav-item>
            </b-nav>
            <b-list-group class="rounded-list">
              <template v-for="(data, index) in detailRound">
                <b-list-group-item
                  v-if="data.count > 0"
                  :key="index"
                  :active="data.active"
                  @click="data.count > 0 && activeRound(index)"
                  class="fw-bold item-list"
                  :class="{ clickable: data.count > 0, dark: isDarkSkin }"
                >
                  {{ data.title }}
                  <b-badge
                    variant="info"
                    class="ml-1 counter-color"
                    v-if="data.count > 0"
                  >
                    {{ data.count }}
                  </b-badge>
                  <br />

                  <ValidationProvider
                    :name="'document' + index"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-radio-group
                      v-model="social[index]"
                      class="d-inline"
                      :class="{
                        'border rounded border-danger': errors[0],
                        'bg-transparent': isDarkSkin,
                      }"
                      style="padding: 2px 0"
                      plain
                    >
                      <template v-for="(option, key) in data.secure_number">
                        <b-form-radio
                          :key="key"
                          v-if="option && option.document !== null"
                          class="custom-radio"
                          :value="option"
                          v-b-tooltip.hover.right="option.document"
                        >
                          <span
                            :class="isDarkSkin ? 'text-white' : 'text-dark'"
                          >
                            {{ option.name }}
                          </span>
                        </b-form-radio>
                      </template>
                    </b-radio-group>
                    <span
                      class="text-danger fw-bold"
                      v-if="errors[0] && data.secure_number.length == 0"
                      >{{ "SSN, CPN or ITIN " + errors[0] }}</span
                    >
                  </ValidationProvider>
                </b-list-group-item>
              </template>
            </b-list-group>
          </ValidationObserver>
          <b-form-group
            label="Comment (optional)"
            label-for="textarea-comment"
            class="mb-0 mt-1"
          >
            <b-form-textarea
              id="textarea-comment"
              v-model="comment"
              placeholder="Enter your text"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <div style="position: relative; overflow-y: auto; max-height: 595px">
            <template v-for="(data, index) in listRound">
              <div
                class="p-0 mx-0 d-flex flex-column justify-content-center align-items-center"
                :key="index"
                style="gap: 15px"
                v-if="detailRound[index].active"
              >
                <div
                  class="w-100"
                  v-for="(sub, subIndex) in data"
                  :key="sub.id"
                >
                  <div class="position-relative w-100">
                    <b-nav card-header pills class="m-0">
                      <b-nav-item
                        active
                        :link-classes="['px-3', bgTabsNavs]"
                        class="fw-bold"
                      >
                        {{ elementName(sub.type_element) }}
                      </b-nav-item>
                    </b-nav>
                  </div>

                  <div :key="sub.id">
                    <template>
                      <b-container>
                        <b-row
                          :key="subIndex"
                          style="border: 1px solid #777777"
                          class="rounded-list"
                        >
                          <b-row class="p-1 text-wrap col-12" align-v="stretch">
                            <b-col cols="3" class="text-wrap">
                              <h5 class="text-left fw-bold">
                                {{ titleName(sub.type_element) }}
                              </h5>
                              <p
                                v-b-tooltip
                                :title="sub.content"
                                class="rounded border-secondary text-center text-truncate text-padding"
                              >
                                {{ sub.content }}
                              </p>
                            </b-col>
                            <b-col cols="3" v-if="sub.a_number">
                              <h5 class="text-left fw-bold">Account#</h5>
                              <p
                                v-b-tooltip
                                :title="sub.a_number"
                                class="rounded border-secondary text-center text-truncate text-padding"
                              >
                                {{ sub.a_number }}
                              </p>
                            </b-col>
                            <b-col
                              cols="3"
                              v-if="sub.type && sub.type_element == 4"
                            >
                              <h5 class="text-left fw-bold">Account Type</h5>
                              <p
                                v-b-tooltip
                                :title="sub.type"
                                class="rounded border-secondary text-center text-truncate text-padding"
                              >
                                {{ sub.type }}
                              </p>
                            </b-col>
                            <b-col cols="3" v-if="sub.a_status">
                              <h5 class="text-left fw-bold">Account Status</h5>
                              <p
                                v-b-tooltip
                                :title="sub.a_status"
                                class="rounded border-secondary text-center text-truncate text-padding"
                              >
                                {{ sub.a_status }}
                              </p>
                            </b-col>
                            <b-col cols="3" v-if="sub.destination">
                              <h5 class="text-left fw-bold">Destination</h5>
                              <p
                                v-b-tooltip
                                :title="sub.destination"
                                class="rounded border-secondary text-center text-truncate text-padding"
                              >
                                {{ sub.destination }}
                              </p>
                            </b-col>
                            <b-col cols="3" v-if="sub.destination">
                              <h5 class="text-left fw-bold">
                                {{
                                  sub.destination == "Bureau"
                                    ? "Bureau"
                                    : "Creditor"
                                }}
                              </h5>
                              <p
                                v-b-tooltip
                                :title="
                                  sub.destination == 'Bureau'
                                    ? getBureauName(sub.bureau_id)
                                    : sub.creditor_name
                                "
                                class="rounded border-secondary text-center text-truncate text-padding"
                              >
                                {{
                                  sub.destination == "Bureau"
                                    ? getBureauName(sub.bureau_id)
                                    : sub.creditor_name
                                }}
                              </p>
                            </b-col>
                            <b-col cols="3" v-if="sub.letter_type">
                              <h5 class="text-left fw-bold">Letter Type</h5>
                              <p
                                v-b-tooltip
                                :title="sub.letter_type"
                                class="rounded border-secondary text-center text-truncate text-padding"
                              >
                                {{ sub.letter_type }}
                              </p>
                            </b-col>
                            <b-col cols="3" v-if="sub.format_name">
                              <h5 class="text-left fw-bold">Format</h5>
                              <p
                                v-b-tooltip
                                :title="sub.format_name"
                                class="rounded border-secondary text-center text-truncate text-padding"
                              >
                                {{ sub.format_name }}
                              </p>
                            </b-col>
                            <b-col cols="3">
                              <h5 class="text-left fw-bold">Reason</h5>
                              <p
                                v-b-tooltip
                                :title="sub.reason_name"
                                class="rounded border-secondary text-center text-truncate text-padding"
                              >
                                {{ sub.reason_name }}
                              </p>
                            </b-col>
                            <b-col cols="3">
                              <h5 class="text-left fw-bold">Instruction</h5>
                              <p
                                v-b-tooltip
                                :title="sub.instruction_name"
                                class="rounded border-secondary text-center text-truncate text-padding"
                              >
                                {{ sub.instruction_name }}
                              </p>
                            </b-col>
                          </b-row>
                        </b-row>
                      </b-container>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <b-button variant="success" @click="processRound">PROCESS ROUND</b-button>
    </template>
    <ModalRecommendService
      v-if="showModalRecommend"
      :dataClient="dataClient"
      :roundLetterId="roundLetterId"
      :idBureau="idBureau"
      :idSection="idSection"
      :comment="comment"
      :roundsToProcess="round"
      @close="showModalRecommend = false"
    />
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReportService from "@/views/commons/components/ncr/service/ncr.service";
import ModalRecommendService from "@/views/commons/components/ncr/components/report/modal/ModalRecommendService.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import TablerIcon from "@/icons/TablerIcon.vue";
import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";
import SNLeadsService from "@/views/social-network/services/leads";

export default {
  components: {
    ModalRecommendService,
    FeatherIcon,
    TablerIcon,
  },
  async created() {
    await this.loadSecureSocial();

    this.listRound = this.round.reduce(function (obj, item) {
      let order_rounds = item.dispute_type == "Direct" ? 0 : item.round_number;
      if (!obj[order_rounds]) {
        obj[order_rounds] = [];
      }

      obj[order_rounds].push(item);

      return obj;
    }, {});
    for (const roundKey in this.listRound) {
      this.$set(this.isCollapseOpen, roundKey, true);
    }
    this.countRound();
    await this.initActiveTab();

    this.finishedLoading = true;
  },
  props: {
    roundLetterId: {
      required: true,
    },
    dataClient: {
      required: true,
    },
    idBureau: {
      required: true,
    },
    idSection: {
      required: true,
    },
  },
  data() {
    return {
      isCollapseOpen: {},
      collapseIcon: "ChevronUpIcon",
      collapseIconClosed: "ChevronDownIcon",
      showModal: true,
      social: [],
      showModalRecommend: false,
      finishedLoading: false,
      detailRound: [
        {
          title: "DIRECT",
          item: "#item_0",
          secure_number: [],
          count: 2,
          orden: 0,
          active: true,
        },
        {
          title: "FIRST ROUND",
          item: "#item_1",
          secure_number: [],
          count: 2,
          orden: 1,
          active: false,
        },
        {
          title: "SECOND ROUND",
          item: "#item_2",
          secure_number: [],
          count: 2,
          orden: 2,
          active: false,
        },
        {
          title: "THIRD ROUND",
          item: "#item_3",
          secure_number: [],
          count: 2,
          orden: 3,
          active: false,
        },
        {
          title: "FOURTH ROUND",
          item: "#item_4",
          secure_number: [],
          count: 2,
          orden: 4,
          active: false,
        },
        {
          title: "FIFTH ROUND",
          item: "#item_5",
          secure_number: [],
          count: 2,
          orden: 5,
          active: false,
        },
      ],
      listRound: [],
      comment: "",
    };
  },
  methods: {
    ...mapActions({
      A_CLEAR_DISPUTE_DATA: "RequestWorkPlanStore/A_CLEAR_DISPUTE_DATA",
    }),
    toggleCollapse(roundKey) {
      this.isCollapseOpen[roundKey] = !this.isCollapseOpen[roundKey];
      this.collapseIcon = this.isCollapseOpen[roundKey]
        ? "ChevronUpIcon"
        : "ChevronDownIcon";
    },
    async processRound() {
      try {
        this.addSocialRound();
        this.$refs.form.validate().then(async (success) => {
          if (!success) {
            return;
          } else {
            this.showQuestionSwal(
              "Would you like to recommend a service?",
              null,
              "Yes",
              "Cancel",
              "question",
              {
                allowEscapeKey: false,
                allowOutsideClick: false,
                showDenyButton: true,
                showCancelButton: true,
                denyButtonText: "No",
                customClass: {
                  confirmButton: "btn btn-outline-success mr-1",
                  cancelButton: "btn btn-outline-warning mr-1",
                  denyButton: "btn btn-outline-danger  mr-1",
                },
                focusConfirm: false,
              }
            ).then(async (result) => {
              if (result.isConfirmed) {
                this.addPreloader();
                this.showModalRecommend = true;
              } else if (result.isDenied) {
                this.addPreloader();
                const { status } = await ReportService.processRound({
                  rounds: this.setBoolToSendRound(this.round),
                });
                if (status == 200) {
                  this.A_CLEAR_DISPUTE_DATA([]);
                  const params = {
                    // id: this.dataClient.id,
                    save_by: this.currentUser.user_id,
                    letters_id: this.roundLetterId,
                    id: this.idSection,
                    comment: this.comment,
                  };
                  if (this.section == "update") {
                    const res =
                      await DisputeResultsService.toRevisionUpdateRequest(
                        params
                      );
                    if (res.status == 200) {
                      this.showSuccessSwal(res.message);
                      this.$emit("reload");
                      if (
                        this.currentRoute == "report-lead-specialists-ad-cr"
                      ) {
                        this.$router.push({
                          name: "sp-update-request-to-revision",
                        });
                      }
                      this.A_CLEAR_DISPUTE_DATA([]);
                      this.closeModal();
                    }
                  } else if (this.section == "workplan") {
                    const res = await ReportService.toRevision(params);
                    if (res.status == 200) {
                      this.showSuccessSwal("Request Workplan completed");
                      if (
                        this.currentRoute == "report-lead-specialists-ad-cr"
                      ) {
                        this.$router.push({ name: "sp-wp-to-revision" });
                      }
                    } else {
                      this.showErrorSwal();
                    }
                  }
                }
              }
              this.removePreloader();
            });
          }
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    setBoolToSendRound(arr) {
      const filteredArr = arr.filter((obj) => obj.dispute_type != "Direct");

      if (filteredArr.length == 0) return arr; // only direct dispute, no actions

      const minValue = Math.min(...filteredArr.map((obj) => obj.round_number)); // some letters dispute

      const newArr = arr.map((obj) => ({
        ...obj,
        bool: obj.round_number == minValue && obj.dispute_type != "Direct", // set bool true first round if exists letters dispute
      }));

      return newArr;
    },
    async loadSecureSocial() {
      try {
        let res;
        if (this.roundLetterId !== null) {
          res = await ReportService.getAllSecureSocial({
            ncrid: this.roundLetterId,
          });
        } else {
          const { data } = await SNLeadsService.getLeadDocument({
            lead_id: this.dataClient.lead_id,
          });
          res = data;
        }
        const data = {
          secure_number: res.flatMap((obj) => {
            return Object.entries(obj)
              .filter(([key, value]) => value !== null)
              .map(([key, value]) => {
                let type_document, name;
                if (key !== "dob") {
                  switch (key) {
                    case "ssn":
                      type_document = 1;
                      name = "SSN";
                      break;
                    case "itin":
                      type_document = 2;
                      name = "ITIN";
                      break;
                    case "other":
                      type_document = 3;
                      name = "CPN";
                      break;
                    default:
                      break;
                  }
                  return {
                    type_document,
                    document: value,
                    name,
                  };
                }
              });
          }),
        };
        this.detailRound.map((item, index) => {
          this.round.find((element) => {
            let round =
              element.dispute_type == "Direct" ? 0 : element.round_number;
            if (round == index) {
              item.secure_number = data.secure_number;
              if (data.secure_number.length === 1) {
                this.social[index] = data.secure_number[0];
              }
            }
          });
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    roundName(number) {
      let round = {
        1: "FIRST ROUND",
        2: "SECOND ROUND",
        3: "THIRD ROUND",
        4: "FOURTH ROUND",
        5: "FIFTH ROUND",
      };
      return round[number] || null;
    },
    countRound() {
      const round = this.round;
      const detailRound = this.detailRound;

      detailRound.forEach((item) => {
        item.count = 0;
      });

      round.forEach((item) => {
        const roundNumber =
          item.dispute_type == "Direct" ? 0 : item.round_number;
        const detailItem = detailRound.find(
          (detail) => detail.orden === roundNumber
        );

        if (detailItem) {
          detailItem.count++;
        }
      });
    },
    closeModal() {
      this.$emit("close");
    },
    addSocialRound() {
      const round = this.round;

      round.forEach((item) => {
        const socialCopy = [...this.social];
        const roundNumber = item.round_number;
        if (socialCopy[0] == undefined) {
          socialCopy.shift();
          if (socialCopy[roundNumber - 1]) {
            item.document = socialCopy[roundNumber - 1].document;
            item.type_document = socialCopy[roundNumber - 1].type_document;
          }
        } else if (socialCopy.length > 1) {
          socialCopy.splice(1, 0, socialCopy.shift());
          if (socialCopy[roundNumber]) {
            item.document = socialCopy[roundNumber].document;
            item.type_document = socialCopy[roundNumber].type_document;
          }
        } else if (socialCopy[roundNumber - 1]) {
          item.document = socialCopy[roundNumber - 1].document;
          item.type_document = socialCopy[roundNumber - 1].type_document;
        }
      });
    },

    elementName(type) {
      let element = {
        1: "Personal Profile",
        2: "Inquiries",
        3: "Public Information",
        4: "Account",
      };
      return element[type] || null;
    },
    titleName(type) {
      let element = {
        1: "Personal dispute",
        2: "Inquiries dispute",
        3: "Public dispute",
        4: "Account",
      };
      return element[type] || null;
    },
    getBureauName(id) {
      const bureaus = {
        1: "TransUnion",
        2: "Experian",
        3: "Equifax",
      };

      return bureaus[id];
    },
    activeRound(index) {
      this.detailRound.map((item, key) => {
        if (key == index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },

    initActiveTab() {
      for (const objeto of this.detailRound) {
        if (
          Array.isArray(objeto.secure_number) &&
          objeto.secure_number.length > 0
        ) {
          objeto.active = true;
          break;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      round: "RequestWorkPlanStore/G_DISPUTE_DATA",
      currentUser: "auth/currentUser",
    }),
    currentRoute() {
      return this.$route.name;
    },
    section() {
      return this.$route.params.section;
    },
  },
};
</script>
<style scoped>
.border-danger {
  border: 2px solid red !important;
}
.accounts p {
  padding: 5px 5px !important;
}
.custom-input .form-control {
  opacity: 1 !important;
}
</style>

<style lang="scss" scoped>
.fw-bold {
  font-weight: bold;
}
.item-list {
  &.active {
    background-color: #d7f0ff !important;
    color: #525252 !important;
    border: 1px solid #d7f0ff;
  }
  &:active {
    background-color: #d7f0ff !important;
    color: #525252 !important;
    border: 1px solid #d7f0ff;
  }
}

.item-list.dark {
  &.active {
    background-color: #373438 !important;
    color: #fff !important;
    border: 1px solid #373438;
  }
  &:active {
    background-color: #2f2e30 !important;
    color: #fff !important;
    border: 1px solid #373438;
  }
}

.counter-color {
  padding: 0.3rem 0.5rem !important;
  background-color: #7de6ff !important;
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  margin-bottom: 8px;
}
.rounded-list {
  border-radius: 0 1rem 1rem 1rem !important;
}
.flag {
  position: absolute;
  top: 0;
  right: 0;
  background: #00d321;
  padding: 2px 20px;
  font-weight: 600;
  border-radius: 30px 0 0 30px !important;
}
.text-padding {
  padding: 2px 5px !important;
}
</style>
